import React from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ImageWithFrame from '../components/ImageWithFrame';
import OSAInfo from '../components/OSAInfo';

const Home = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <Typography variant="body1">
            Kära gäster,
            <br /> På grund av stora osäkerheten som fortfarande råder kring
            pandemiläget så har vi beslutat att (igen) skjuta fram vårt bröllop.
            Denna gång till våren 2022.
            <br />
            Nya tider och datum är:
          </Typography>
          <Typography variant="body1">
            Datum för bröllopet: 7 maj 2022
            <br />
            Tid för vigseln: kl: 14:30
            <br />
            Nytt datum för OSA: 9 april 2022
          </Typography>

          <Typography variant="h3">Allmän information</Typography>
          <Typography variant="body1">
            Vigsel sker i Danmarks kyrka i Uppsala. Bröllopsfesten kommer att
            hållas i Danmarks bygdegård, som ligger mitt över gatan från kyrkan.
            Läs mer om hur ni hittar till kyrka och festlokal under{' '}
            <Link to="/transport">Transport</Link>.
          </Typography>

          <Typography variant="h3">Barn</Typography>
          <Typography variant="body1">
            Vi tycker mycket om barn, men denna dag vill vi tillbringa endast i
            vuxnas sällskap. Ammande spädbarn är dock givetvis välkomna.{' '}
          </Typography>

          <Typography variant="h3">OSA</Typography>
          <OSAInfo />
        </Grid>

        <Grid item md={6} xs={12}>
          <ImageWithFrame
            src="Adam_och_Jennifer_1_JA_MJFoto.jpg"
            alt="Adam och Jennifer"
          />
        </Grid>
      </Grid>
      <Box my={4}>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <ImageWithFrame src="munbind.jpg" alt="Adam och Jennifer i munbind" />
        </Grid>
        <Grid xs={12} md={6}>
          <Box pl={2} pt={2}>
            <Typography variant="h3">Covid-19</Typography>
            <Typography variant="body1">
              Vi hoppas på att kunna genomföra vigsel och bröllopsfest som
              planerat trots rådande pandemi. Vi följer självklart
              myndigheternas riktlinjer, och skulle vi tvingas ändra i
              planeringen kommer vi meddela detta snarast på denna hemsida.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
