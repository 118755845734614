import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CountDown from './Countdown';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: 'url("background.jpg")',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center',
    },
    backgroundSize: 'cover',
  },
  headerText: {
    textAlign: 'center',
    fontSize: '5rem',
    fontFamily: 'Dancing Script',
    paddingTop: '32px',
    paddingBottom: '8px',
  },
  date: {
    textAlign: 'center',
    fontSize: '2rem',
    fontFamily: 'Dancing Script',
    paddingBottom: '8px',
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <Box className={classes.header}>
      <Typography className={classes.headerText}>Jennifer och Adam</Typography>
      <Typography className={classes.date}>7 Maj 2022</Typography>
      <CountDown />
    </Box>
  );
};

export default Header;
