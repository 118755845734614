import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ImageWithFrame from '../components/ImageWithFrame';

const Kuriosa = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <Typography variant="h2">Kuriosa</Typography>
          <Typography variant="h3">Hur träffades Adam och Jennifer?</Typography>
          <Typography variant="body1">
            Vi träffades för första gången år 2015 genom gemensamma bekanta.
            Även om båda parter fattade tycke för varandra redan vid första
            mötet så dröjde det ända till 2018 innan vi blev ett par. När vi väl
            besluatde oss så skedde allt med en väldig fart, vi blev ett par och
            sambos på samma dag. Vi förlovade oss sedan på vår 1-årsdag, och ett
            halvår senare så flyttade vi till vår gemensamma favoritstad
            Köpenhamn.
          </Typography>

          <Typography variant="h3">Varför Sambrink?</Typography>
          <Typography variant="body1">
            När vi gifter oss kommer vi båda ta efternamnet Sambrink. Namnet
            kommer från brudgummens mors farbror Seth och hans fru Mimmi, som
            bytte från Karlsson till Sambrink. Senare tog även brudgummens
            morfar namnet Sambrink, och därmed fick även brudgummens mor Helén
            efternamnet Sambrink när hon föddes. Vi båda ser fram emot att gå in
            i detta nya liv tillsammans med ett för oss båda nytt namn som vi
            båda gillar.
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <ImageWithFrame
            src="Set_och_mimi.png"
            alt="Adam och Jennifer"
            caption="Seth och Mimmi - the original Sambrink"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ImageWithFrame src="pride.jpg" alt="Adam och Jennifer" />
        </Grid>
        <Grid md={6} xs={12}>
          <Typography variant="h3">Vigselförrättare</Typography>
          <Typography variant="body1">
            Vigselförrättare kommer vara brudgummens farfar Kjell Byström. Kjell
            är prästvigd och har bland annat vigt brudgummens föräldrar Mats
            Byström och Helén Sambrink Byström, samt döpt de flesta barn i
            släkten Byström. Vi är därför mycket glada och stolta över att Kjell
            har tackat ja till att förrätta även vår vigsel!
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Kuriosa;
