import {
  Box,
  Collapse,
  Hidden,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  menuWrapper: {
    backgroundColor: '#dbabb4',
    maxWidth: '100%',
  },
  menuList: {
    display: 'flex',
    flexDirection: 'row',
    listStyle: 'none',
    justifyContent: 'center',

    paddingRight: '10px',
    paddingTop: '8px',
    paddingBottom: '8px',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      padding: theme.spacing(1, 2),
    },
    '& a:hover': {
      fontWeight: 'bold',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',

      '& a': {
        padding: theme.spacing(2, 4),
      },
    },
  },
  expandMenuButton: {
    padding: theme.spacing(2, 4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const Menu = () => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const isLargerScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [expanded, setExpanded] = useState(isLargerScreen);

  useEffect(() => {
    setExpanded(isLargerScreen);
  }, [location.pathname, isLargerScreen]);

  return (
    <nav className={classes.menuWrapper}>
      <Hidden mdUp>
        <Box
          className={classes.expandMenuButton}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <MenuIcon style={{ color: 'white' }} />
        </Box>
      </Hidden>
      <Collapse in={expanded}>
        <Box className={classes.menuList}>
          <Link to="/">Start</Link>
          <Link to="/transport">Transport</Link>
          <Link to="/tal">Middag och tal</Link>
          <Link to="/presenter">Klädkod och presenter</Link>
          <Link to="/kuriosa">Kuriosa</Link>
          <Link to="/osa">OSA och kontakt</Link>
        </Box>
      </Collapse>
    </nav>
  );
};

export default Menu;
