import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import ImageWithFrame from '../components/ImageWithFrame';
import OSAInfo from '../components/OSAInfo';

const OSA = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography variant="h2">OSA</Typography>
          <OSAInfo />

          <Typography variant="h3">Frågor?</Typography>
          <Typography variant="body1">
            Något som känns oklart? Maila gärna era frågor till{' '}
            <a href="mailto:adamochjennifer2021@gmail.com">
              adamochjennifer2021@gmail.com
            </a>{' '}
            eller hör av er till oss personligen!{' '}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <ImageWithFrame
            src="Adam_och_Jennifer_3_JA_MJFoto.jpg"
            alt="Adam och Jennifer"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OSA;
