import React from 'react';
import { Typography } from '@material-ui/core';

const OSAInfo = () => (
  <Typography variant="body1">
    OSA genom att skicka ett mail till{' '}
    <a href="mailto:adamochjennifer2021@gmail.com">
      adamochjennifer2021@gmail.com
    </a>{' '}
    senast <strong>9 april 2022</strong> med följande information:
    <ul>
      <li>För- och efternamn</li>
      <li>Telefonnummer</li>
      <li>Ifall ni önskar komma på vigseln i kyrkan och festen därefter</li>
      <li>Om ni önskar alkoholfri eller alkoholhaltig dryck till middagen</li>
      <li>Eventuell specialkost</li>
    </ul>
  </Typography>
);

export default OSAInfo;
