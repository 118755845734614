import React, { useMemo, useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  timeLeft: {
    textAlign: 'center',
    paddingBottom: '32px',
  },
}));

const CountDown = () => {
  const [now, setNow] = useState(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const dateToDisplay = useMemo(() => {
    const date = new Date('May 7, 2022 14:30:00 GMT+2').getTime();
    const distance = date - now;
    const days = Math.floor(Math.abs(distance) / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (Math.abs(distance) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (Math.abs(distance) % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((Math.abs(distance) % (1000 * 60)) / 1000);

    return `${days} dagar, ${hours} timmar ${minutes} minuter och ${seconds} sekunder ${
      distance > 0 ? 'kvar' : 'sedan'
    }`;
  }, [now]);

  const classes = useStyle();

  return <Box className={classes.timeLeft}>{dateToDisplay}</Box>;
};

export default CountDown;
