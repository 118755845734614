import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ImageWithFrame from '../components/ImageWithFrame';

const Tal = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography variant="h2">Middag och tal</Typography>

          <Typography variant="h3">Anmälan av tal och spex</Typography>
          <Typography variant="body1">
            Alla gäster är mycket välkomna att hålla tal eller uppträda med spex
            under bröllopsmiddagen. Vi ser dock gärna att ni anmäler detta i
            förhand till vår toastmaster Oscar Byström och toastmadame Erica
            Wendel. Detta görs enklast på email:{' '}
            <a href="mailto:toastmasters.sambrink2021@gmail.com">
              toastmasters.sambrink2021@gmail.com
            </a>
            . I lokalen finns både ljudsystem och projektor. För enkelhetens
            skull, skicka gärna eventuella saker ni vill spela upp på dessa till
            Oscar och Erica.
          </Typography>
          <Typography variant="h3">Middag och fest</Typography>
          <Typography variant="body1">
            Efter vigseln kommer vi att servera en 4-rätters bröllopsmiddag
            bestående av förrätt, buffé, ostbricka och bröllopstårta. Har du
            allergier eller kostrestriktioner ska du anmäla detta under{' '}
            <Link to="/osa">OSA</Link>. detsamma gäller om du önskar alkoholfri
            dryck till middagen. Efter middagen kommer det finnas en öppen bar
            som serverar öl, vin, drinkar och annat. Baren kommer vara bemannad
            med bartender hela kvällen.
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <ImageWithFrame src="adam_skumpa.jpg" alt="Adam och Jennifer" />
        </Grid>
      </Grid>
    </>
  );
};

export default Tal;
