import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import ImageWithFrame from '../components/ImageWithFrame';

const TransportOchBoende = () => {
  return (
    <Box>
      <Typography variant="h2">Transport</Typography>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <Typography variant="h3">Transport till vigsel</Typography>
          <Typography variant="body1">
            Transport till kyrkan ordnas på egen hand. Adressen till kyrkan är{' '}
            <a
              href="https://goo.gl/maps/kmjRT7djYd6QuMbB9"
              target="_blank"
              rel="noreferrer"
            >
              Danmarks kyrkby 755 98 Uppsala
            </a>
            , vilket är ca 8 km från Uppsalas stadskärna. Vill man åka
            kollektivt så kan man ta regionsbuss 102 från Uppsala
            centralstation. Resan tar ca 10 min, hållplatsen närmast kyrkan
            heter ”Danmarks Kyrka”. Ni kan läsa mer om tider och dylikt på{' '}
            <a href="https://www.ul.se/">ul.se</a>. Självklart går det också bra
            att åka taxi eller egen bil. Vi kommer försöka förhandla fram ett
            fast taxipris inom kort.
          </Typography>
        </Grid>
        <Grid item md={6} sm={12}>
          <ImageWithFrame src="Kyrka.jpg" alt="Danmarks kyrka" />
        </Grid>
      </Grid>

      <Typography variant="h3">Transport till och från bröllopsfest</Typography>
      <Typography variant="body1">
        Middag och bröllopsfest kommer ske i Danmarks Bygdegård, som ligger rakt
        över gatan från Danmarks kyrka. Transport från festen ordnar man själv.
        Även här kan man åka kollektivt, regionsbuss 102 går även nattetid. Om
        ni önskar ta taxi så finns både{' '}
        <a href="https://www.uppsalataxi.se/">Uppsala Taxi</a> och{' '}
        <a href="https://www.taxikurir.se/">Taxi kurir</a> i Uppsala.
      </Typography>
    </Box>
  );
};

export default TransportOchBoende;
