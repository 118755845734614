import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imageFrame: {
    border: '1px solid #c8c8c8',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    WebkitBoxShadow: '7px 7px 5px 0px rgba(200, 200, 200, 0.55)',
    MozBoxShadow: '7px 7px 5px 0px rgba(200, 200, 200, 0.55)',
    boxShadow: '7px 7px 5px 0px rgba(200, 200, 200, 0.55)',
  },
}));

const ImageWithFrame = ({ src, alt, caption }) => {
  const classes = useStyles();

  return (
    <Box className={classes.imageFrame}>
      <img src={src} alt={alt} />
      {caption && <Typography variant="caption">{caption}</Typography>}
    </Box>
  );
};

export default ImageWithFrame;
