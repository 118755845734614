import { Box, Container, makeStyles, Paper, ThemeProvider } from '@material-ui/core';
import { Route, Switch, BrowserRouter as Router, } from 'react-router-dom';
import Header from './components/Header';
import Menu from './components/Menu';
import Home from './pages/Home'
import Kuriosa from './pages/Kuriosa';
import OSA from './pages/OSA';
import Presenter from './pages/Presenter';
import Tal from './pages/Tal';
import TransportOchBoende from './pages/TransportOchBoende';
import ourTheme from './theme'

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    paddingTop: '24px',
  },
  contentWrapper: {
    padding: theme.spacing(3, 2, 2, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 8, 8, 8),
    }
  }
}))

function App() {
  const classes = useStyles()
  return (
    <ThemeProvider theme={ourTheme}>
      <Router>
        <Box className={classes.pageWrapper}>
          <Container maxWidth="md" >
            <Paper className={classes.container} >
              <Header />
              <Menu />
              <Box className={classes.contentWrapper}>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/tal">
                    <Tal />
                  </Route>
                  <Route exact path="/transport">
                    <TransportOchBoende />
                  </Route>
                  <Route exact path="/presenter">
                    <Presenter />
                  </Route>
                  <Route exact path="/osa">
                    <OSA />
                  </Route>
                  <Route exact path="/kuriosa">
                    <Kuriosa />
                  </Route>
                </Switch>
              </Box>
            </Paper>
          </Container>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
