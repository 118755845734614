import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
 typography: {
  h2: {
   fontSize: '32px',
   lineHeight: '48px',
   paddingBottom: '16px'
  },
  h3: {
   fontSize: '20px',
   lineHeight: '24px',
   paddingBottom: '8px',
  },
  h4: {
   fontSize: '16px',
   lineHeight: '20px',
   fontWeight: 'bold',
   paddingBottom: '8px',
  },
  body1: {
   paddingBottom: '32px'
  }
 }
})

export default theme