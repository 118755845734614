import { Box, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import ImageWithFrame from '../components/ImageWithFrame';

const Presenter = () => {
  return (
    <Box>
      <Typography variant="h2">Klädkod och presenter</Typography>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <ImageWithFrame src="balklader.jpg" alt="Öl och vin i regnet" />
        </Grid>
        <Grid item md={6} sm={12}>
          <Typography variant="h3">Klädkod</Typography>
          <Typography variant="body1">
            På denna speciella dag ser vi gärna att våra gäster kommer
            finklädda. Vi har därför bestämt oss för att ha klädkoden kavaj. I
            klädkoden ingår hel kostym, kjol, klänning, byxdress med mera.
            Givetvis är vi inte jättestrikta med klädkoden, men nedan hittar du
            generella riktlinjer.
          </Typography>

          <Typography variant="h4">Kavaj</Typography>
          <Typography variant="body1">
            För den som vill bära kavaj gäller hel kostym, skjorta och gärna en
            slips eller fluga. Färgen på alla plagg är valfri.
          </Typography>
          <Typography variant="h4">Klänning och kjol</Typography>
          <Typography variant="body1">
            Klädkoden “kavaj” innebär också att man kan bära klänning, kjol,
            byxdress, eller en top med finare byxor. Observera att ingen kavaj
            behövs i kombination med detta. Utstyrseln ska dock vara finare än
            vanlig vardagsklädsel. Färg på plaggen är valfri.
          </Typography>
          <Box my={2} />
        </Grid>
      </Grid>
      <Box mt={4} mb={5}>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <Typography variant="h3">Bröllopspresent</Typography>
          <Typography variant="body1">
            Det viktigaste är att ni kommer och firar med oss! Ifall ni vill ge
            oss något utöver er närvaro under denna dag så ser vi gärna ett
            ekonomiskt bidrag till vår bröllopsresa.
          </Typography>
          <Box my={2} />
          <Typography variant="body1">
            Clearingnummer: 6501
            <br />
            Kontonummer: 175 185 158
          </Typography>
        </Grid>
        <Grid item md={6} sm={12}>
          <ImageWithFrame
            src="Adam_och_Jennifer_2_JA_MJFoto.jpg"
            alt="Öl och vin i regnet"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Presenter;
